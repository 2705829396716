.gallery-setting {
    width: 100%;
    margin-bottom: 40px;
}
.gallery-setting h1 {
    color: var(--green-white-100);
    font-size: 2rem;
    font-weight: bold;
}

a {
    color: cyan;
}
.search-bar {
    position: relative;
    width: 600px;
    height: 60px;
    margin-top: 30px;
    padding: 0 8px 0 20px;
    column-gap: 20px;
    background-image: url(/public/img/search/search.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.search-input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.1rem;
    color: var(--white-100);
}

.search-input::placeholder {
    color: var(--dark-600);
}

.search-btn {
    position: relative;
    width: 50px;
    height: 40px;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
}

.search-btn::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-image: url(/public/img/search/SearchButton.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}
