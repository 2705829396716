@import "./css-reset.css";

:root {
    --white-100: #FFFFFF;
    --dark-900: #09090A;
    --dark-600: #808090;
    --green-400:#2beaee; /* // #00FF47;*/
    --green-white-100: #c1d1e1; /* #C3EFC0;*/
    --blue-700: #0064bd;
}

body {
    background: var(--dark-900);
}

/* my own classes */
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}

.full-width-div {
        position: absolute;
        left: 50px;
        width: calc(100% - 100px);
        border: 1px solid black;
        background-color: yellow;
        padding: 5px;
        text-align: center;
    }

@media screen and (min-width: 960px) {
    #appplaystore {
        display: flex !important;
    }
}
@media screen and (max-width: 600px) {
    #appplaystore {
        display: block !important;
    }
}

