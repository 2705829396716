.button {
    padding: 8px 18px;
    border-radius: 16px;
    cursor: pointer;
}
.button.matrix {
    background-color: var(--green-400);
    transition: .4s;
}
.button.matrix:hover {
    filter: brightness(0.8);
}
.button.transparent {
    background-color: transparent;
    color: var(--white-100);
}